<template>
  <div class="flix-form-group">
    <div class="flix-panel flix-panel-default">
      <div class="flix-panel-heading">
         <flixIcon id="flag" /> {{ $tc('message.targetPage', 1) }} <span class="flix-pull-right"><a href="#" class="flix-html-a flix-text-danger" @click.prevent="function() { onDelete(save.ID) }"><flixIcon class="flix-text-danger" id="bin" /></a></span>
        <div class="flix-pull-right"></div>
      </div>
      <div class="flix-panel-body">
        <div class="flix-form-group">
          <a href="#" @click.prevent="open = !open" class="flix-html-a"><flixIcon id="chevron-up" v-if="!open"/><flixIcon id="chevron-down" v-else /> {{ $tc('message.pleaseUseBookingForm', 2) }} ({{ save.assistents.length }})</a>
        </div>
        <transition name="flixFadeIn" :key="open">
            <list :data="save" :callback="function(ret) { save.assistents = ret; onChangeAssistents(save.ID) }" v-if="open"/>
        </transition>
      </div>
      <div class="flix-panel-footer">
        <targetPageFooter :data="save" :onEdit="function(id) { onEdit(id) }" />
      </div>

    </div>
  </div>
</template>
<script>
export default {
  components: {
    list () { return import('@/components/targetPage/list') },
    targetPageFooter () { return import('@/components/targetPage/footer') }
  },
  props: {
    onEdit: Function,
    onDelete: Function,
    onChangeAssistents: Function,
    save: [Object, Array]
  },
  mounted () {

  },
  computed: {

  },
  data () {
    return {
      open: true
    }
  },
  methods: {

  }
}
</script>
<style lang="sass" scoped>
</style>
